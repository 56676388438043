import React from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';

const Nav = () => (
  <Container>
    <ul>
      <li>
        <Link to="/40k">Warhammer 40,000</Link>
      </li>
      <li>
        <Link to="/aos">Age of Sigmar</Link>
      </li>
      <li>
        <Link to="/dnd">D&D</Link>
      </li>
      <li>
        <Link to="/blog">Blog</Link>
      </li>
      <li>
        <Link to="/about">About</Link>
      </li>
    </ul>
  </Container>
);

export default Nav;
